<template lang="">
  <v-container>
    <v-card>
      <v-card-title> Create Runner </v-card-title>
      <v-card-text>
        <v-text-field :label="title" v-model.trim="title"></v-text-field>
        <v-text-field :label="api" v-model.trim="apiURL"></v-text-field>
        <v-text-field :label="method" v-model.trim="apiURL"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="create()">
          <span v-html="$t('createRunner')" />
        </v-btn>
        <v-btn color="secondary" @click="autoStart = true">
          <span v-html="$t('runAll')" />
        </v-btn>
        <v-btn color="secondary" @click="autoStart = false">
          <span v-html="$t('stoppAll')" />
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col v-for="(runner, index) in runners" :key="'runner' + index">
        <runner :apiURL="apiURL" :api="runner.api" :title="runner.title" :method="runner.method" :autoStart="autoStart" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import vNumberField from '@/components/v-number-field'

import { apiURL } from '../../main'
import runner from './runner.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'toasts',
  data() {
    return {
      apiURL: `${location.protocol}//${location.hostname}:${location.port}${apiURL}`,
      title: '',
      api: '',
      method: 'get',
      autoStart: false,
      runners: [
        { title: 'privileges', api: `/privileges`, method: 'get' },
        {
          title: 'langDe',
          api: `/translations/language/de?domainId=1`,
          method: 'get'
        },
        {
          title: 'langFr',
          api: `/translations/language/fr?domainId=1`,
          method: 'get'
        },
        {
          title: 'prodF/Find',
          api: `/productionForms/find?term=Dairy_ident%3A303&periodeYear=2022&periodeMonth=02&loadPreviousMonths=PREVIOUS&page=0&size=50&period=2022-02-01&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'prodF/AnimalGroups',
          api: `/productionForms/64/animalGroups?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'prodF/updateRows',
          api: `/productionForms/64/updateRows?originId=1&domainId=1`,
          method: 'patch'
        },
        {
          title: 'prodF/64/animalGroups',
          api: `/productionForms/64/animalGroups?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'prodF/loadPreviousMonth',
          api: `/productionForms/64/1/rows?loadPreviousMonths=PREVIOUS&term=Dairy_ident%3A303&periodeYear=2022&periodeMonth=02&page=0&size=50&sort=farmDairyNo&sort=zip&period=2022-02-01&sortDesc=false&sortDesc=false&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'prodF/64/1/10001/rows',
          api: `/productionForms/64/1/10001/rows?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'prodF/64/1/10001/history',
          api: `/productionForms/64/1/10001/history?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'suche "van"',
          api: `/masterdata/search?term=Contact_name1~van&page=0&size=50&sort=localUnitStatus,asc&sort=farmerStatus,asc&sort=legalEntityName1,asc&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'suche "nan"',
          api: `/masterdata/search?term=Contact_name1~nan&page=0&size=50&sort=localUnitStatus,asc&sort=farmerStatus,asc&sort=legalEntityName1,asc&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'suche "303"',
          api: `/masterdata/search?term=Dairy_ident:303&page=0&size=50&sort=localUnitStatus,asc&sort=farmerStatus,asc&sort=legalEntityName1,asc&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'subsidyProductionprocess cred3',
          api: `/subsidyInvoices/production/process?subsidyCreditorId=3&domainId=1`,
          method: 'post'
        },
        {
          title: 'subsidyProductionprocess cred4',
          api: `/subsidyInvoices/production/process?subsidyCreditorId=4&domainId=1`,
          method: 'post'
        },
        {
          title: 'procF/find',
          api: `/processingForms/find?page=0&size=10&sort=statusIdent%2Casc&sort=validUntil%2Cdesc&dairyIdent=303&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/preview',
          api: ` /processingForms/2/processingFormPreview?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/crossCheck',
          api: `/processingForms/2/processingFormAndMarketedMilkCrossCheck?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/2',
          api: `/processingForms/2?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'document',
          api: `/documents/search/findByTagContains?size=10000&tag=processingForms_2&sort=id%2Cdesc&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/formStatus',
          api: `/formStatus?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/calculateValidation',
          api: ` /processingForms/2/calculateValidation?originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/validationGroup',
          api: `/processingForms/2/validationGroups?processingFormId=2&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/validSummary',
          api: `/processingForms/2/validationSummary?processingFormId=2&originId=1&domainId=1`,
          method: 'get'
        },
        {
          title: 'procF/validaition754',
          api: ` /processingForms/2/formValidation/715/treeRelevantRows?originId=1&domainId=1`,
          method: 'get'
        }
      ]
    }
  },
  components: {
    runner,
    vNumberField: vNumberField
  },
  methods: {
    create() {
      this.runners.push({
        api: this.api,
        method: this.method,
        title: this.title
      })
    }
  },
  mounted() {
    // superAdmin  "productGroups":
    if (!this.$privileges.has({ path: '/productGroups', permission: 'write' })) {
      this.$router.push({ name: 'home' }).catch(() => {
        /* duplicated route */
      })
    }
  }
})
</script>
<style lang=""></style>
