<template lang="">
  <v-container>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ this.api }}
        <strong>{{ progression }}</strong>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="run()">
          <span v-html="$t('start')" />
        </v-btn>
        <v-btn color="secondary" @click="interrupt = true">
          <span v-html="$t('pause')" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    apiURL: { String },
    api: { String },
    title: { String },
    method: { String, default: () => 'get' },
    autoStart: { Boolean, default: () => 'false' }
  },
  name: 'runner',
  data() {
    return {
      progression: 0,
      interrupt: false
    }
  },
  watch: {
    autoStart: {
      handler(newvalue) {
        if (newvalue) {
          this.run()
        } else {
          this.interrupt = true
        }
      }
    }
  },
  methods: {
    async run() {
      this.interrupt = false
      while (!this.interrupt) {
        this.progression++

        switch (this.method.toLowerCase()) {
          case 'get': {
            const response = await this.axios.get(this.apiURL + this.api)
            if (response) {
            }
            break
          }
          case 'post': {
            const response2 = await this.axios.post(this.apiURL + this.api)
            if (response2) {
            }
            break
          }
          case 'put': {
            const response3 = await this.axios.put(this.apiURL + this.api)
            if (response3) {
            }
            break
          }
          case 'patch': {
            const response4 = await this.axios.patch(this.apiURL + this.api)
            if (response4) {
            }
            break
          }
        }
      }
    }
  }
})
</script>
<style lang=""></style>
